var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "glide-carousel",
    { staticClass: "carousel--align-top", attrs: { carousel: true } },
    _vm._l(_vm.groupedStudents.scoreCategories, function (category) {
      return _c(
        "glide-carousel-slide",
        { key: category.name, attrs: { slot: "slide" }, slot: "slide" },
        [
          _c(
            "div",
            { staticClass: "progress-modal-slide px-7 pt-1" },
            [
              _c(
                "div",
                {
                  class: `text-align-center score-color score-color--${category.name}`,
                },
                [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$t("generic.score_category." + category.name))
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(category.students, function (student) {
                return _c("div", { key: student.userReference }, [
                  _vm._v(_vm._s(student.fullName)),
                ])
              }),
            ],
            2
          ),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }