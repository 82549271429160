export default class TableOfContents {
  constructor (element) {
    this.element = element

    const toggleButton = this.element.querySelector('.js-toc-toggle')

    if (toggleButton === null) {
      throw Error('Table of contents component must contain a toggle button')
    }

    toggleButton.addEventListener('click', (event) => {
      event.preventDefault()
      document.body.classList.toggle('is-toc-open')
    })

    this.element.querySelectorAll('.js-toc-item').forEach(item => {
      item.addEventListener('click', () => {
        if (item.getAttribute('href').startsWith('#')) {
          document.body.classList.remove('is-toc-open')
        }
      })
    })
  }
}
