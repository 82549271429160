var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        _vm.useRedesign ? "redesign-modal" : "modal",
        {
          tag: "component",
          staticClass: "modal--notifications",
          attrs: {
            type: _vm.ModalType.emptyCondensed(),
            loading: _vm.isLoading,
          },
          on: { close: _vm.close },
        },
        [
          _vm.useRedesign
            ? _c(
                "div",
                {
                  staticClass: "flex flex-col overflow-auto relative js-focus",
                  attrs: { tabindex: "0" },
                  on: {
                    keydown: _vm.onKeydown,
                    mouseenter: function ($event) {
                      _vm.showScrollbar = true
                    },
                    mouseleave: function ($event) {
                      _vm.showScrollbar = false
                    },
                  },
                },
                [
                  _vm.isLoading ? _c("loading-bar") : _vm._e(),
                  _vm._v(" "),
                  _c("div", { staticClass: "px-4" }, [
                    _c(
                      "div",
                      {
                        staticClass: "py-4 font-bold border-b border-gray-500",
                      },
                      [_vm._v(_vm._s(_vm.$t("component.notifications.title")))]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "relative flex flex-col overflow-hidden mr-1",
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "overflow-auto flex-1 pl-4 pr-3 -mb-px",
                          class: _vm.scrollbarClasses,
                        },
                        [
                          _vm._l(
                            _vm.notifications,
                            function (notification, index) {
                              return _c("notification", {
                                key: notification.id,
                                ref: "notifications",
                                refInFor: true,
                                attrs: {
                                  index: index,
                                  notification: notification,
                                  "show-method": _vm.showMethod,
                                  "is-preview": false,
                                  "use-redesign": _vm.useRedesign,
                                },
                                on: {
                                  markRead: _vm.markNotificationAsRead,
                                  delete: _vm.deleteNotification,
                                  click:
                                    _vm.markNotificationAsReadAndGotoSubject,
                                },
                              })
                            }
                          ),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: !_vm.hideShowMore,
                                  expression: "!hideShowMore",
                                },
                              ],
                            },
                            [
                              _c(
                                "a",
                                {
                                  ref: "showMoreResults",
                                  staticClass:
                                    "block py-2 border-b border-gray-500 cursor-pointer",
                                  attrs: { tabindex: "0" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.showMoreResults.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                    keydown: function ($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      )
                                        return null
                                      $event.preventDefault()
                                      return _vm.showMoreResultsAndRefocus.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        _vm.isLoading
                                          ? "component.notifications.loading"
                                          : "component.notifications.view_more"
                                      )
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _vm.didInitialLoad && _vm.notifications.length === 0
                            ? _c("div", { staticClass: "p-4" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "component.notifications.no_notifications"
                                    )
                                  )
                                ),
                              ])
                            : _vm._e(),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("scrollbar-overlay", {
                        attrs: { show: _vm.showScrollbar },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticClass: "px-4" }, [
                    _c(
                      "a",
                      {
                        staticClass:
                          "block font-bold py-2 border-t border-gray-500 cursor-pointer",
                        attrs: { tabindex: "0" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.readAll.apply(null, arguments)
                          },
                          keydown: function ($event) {
                            if (
                              !$event.type.indexOf("key") &&
                              _vm._k(
                                $event.keyCode,
                                "enter",
                                13,
                                $event.key,
                                "Enter"
                              )
                            )
                              return null
                            $event.preventDefault()
                            return _vm.readAll.apply(null, arguments)
                          },
                        },
                      },
                      [
                        _c("span", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$t("component.notifications.read_all")
                            ),
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              )
            : [
                _c("span", { staticClass: "notifications__title" }, [
                  _vm._v(_vm._s(_vm.$t("component.notifications.title"))),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "notifications" },
                  [
                    _vm._l(_vm.notifications, function (notification, index) {
                      return _c("notification", {
                        key: notification.id,
                        attrs: {
                          index: index,
                          notification: notification,
                          "show-method": _vm.showMethod,
                          "is-preview": false,
                          "use-redesign": _vm.useRedesign,
                        },
                        on: {
                          markRead: _vm.markNotificationAsRead,
                          delete: _vm.deleteNotification,
                          click: _vm.markNotificationAsReadAndGotoSubject,
                        },
                      })
                    }),
                    _vm._v(" "),
                    _c("span", {
                      ref: "showMoreResults",
                      staticClass: "notifications__observer",
                    }),
                    _vm._v(" "),
                    _vm.didInitialLoad && _vm.notifications.length === 0
                      ? _c("div", { staticClass: "notifications__empty" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("component.notifications.no_notifications")
                            )
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "a",
                  {
                    staticClass: "notifications__read-all",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.readAll()
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "icon-check" }),
                    _c("span", {
                      domProps: {
                        innerHTML: _vm._s(
                          _vm.$t("component.notifications.read_all")
                        ),
                      },
                    }),
                  ]
                ),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }