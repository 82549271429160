<template>
  <modal :loading="loading" v-if="isOpen" :close-button-inside="true" @close="close">
    <div class="p-3 has-border-bottom">
      <labeled-title heading="h5" :text="$t('component.progress.modal.title')" class="mb-0"></labeled-title>
    </div>
    <div class="p-3" v-if="loading">
      ...
    </div>
    <template v-else>
      <breadcrumbs :chapter-position="breadcrumbs.chapterPosition" :items="breadcrumbs.items" class="p-3"></breadcrumbs>
      <tabs :bordered="true" :animated="false" tabs-additional-class="mx-3">
        <tab :name="$t('generic.term.exercises')" :uid="1" :active="true" :render-hidden="false">
          <assignments :grouped-assignments="groupedAssignments"></assignments>
        </tab>
        <tab :name="$t('generic.term.students')" :uid="2" :render-hidden="false">
          <students :grouped-students="groupedStudents"></students>
        </tab>
      </tabs>
    </template>
  </modal>
</template>

<script>
  import { EventBus, PROGRESS_MODAL_OPEN_EVENT } from '../../common/EventBus'
  import Modal from '../modal/Modal'
  import LabeledTitle from "../LabeledTitle";
  import Breadcrumbs from "../Breadcrumbs";
  import Tabs from '../tabs/Tabs'
  import Tab from '../tabs/Tab'

  import request from '../../util/request'
  import Assignments from './modal/Assignments'
  import Students from './modal/Students'
  import GroupedAssignments from './modal/GroupedAssignments'
  import GroupedStudents from './modal/GroupedStudents'

  export default {
    components: {
      Assignments,
      Students,
      Breadcrumbs,
      LabeledTitle,
      Modal,
      Tabs,
      Tab
    },
    data() {
      return {
        isOpen: false,
        url: null,
        loading: false,
        breadcrumbs: {
          chapterPosition: 0,
          items: []
        },
        groupedAssignments: null,
        groupedStudents: null,
      }
    },
    props: {
      scoreCategories: Array
    },
    created() {
      EventBus.$on(PROGRESS_MODAL_OPEN_EVENT, url => {
        this.url = url
        this.isOpen = true
        this.load()
      })
    },
    methods: {
      close() {
        this.isOpen = false
      },
      async load() {
        this.loading = true
        try {
          const {data} = await request(this.url)

          this.breadcrumbs.chapterPosition = data.chapter.position;
          this.breadcrumbs.items = [
            {label: data.chapter.number, text: data.chapter.title},
            {label: data.paragraph.number, text: data.paragraph.title},
          ];
          this.groupedAssignments = new GroupedAssignments(data.paragraphChildren, this.scoreCategories)
          this.groupedStudents = new GroupedStudents(data.results, this.scoreCategories)
        } catch (e) {
          console.error(e)
        } finally {
          this.loading = false
        }
      }
    }
  }
</script>
