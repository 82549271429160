import NavigationState from '../util/NavigationState'

const ACTIVE_TAB_CLASS = 'tabs__link--active'

export default class Tabs {
  constructor (element) {
    this.element = element
    this.activeTab = this.element.querySelector(`.${ACTIVE_TAB_CLASS}`)

    if (this.activeTab === null) {
      throw Error('No active tab found on page')
    }

    this.navigationState = new NavigationState(document.location.pathname, () => {
      const tab = document.querySelector('[href="' + document.location.pathname + '"]')
      if (tab) {
        this.openTab(tab, false)
      }
    })

    const activePageId = this.activeTab.getAttribute('href')

    this.activePage = document.getElementById(activePageId)
    this.activePage.classList.remove('is-hidden')
    // this is done because browsers sometimes retain the scroll position when loading a page
    setTimeout(() => {
      this.scrollToHashFragment()
    }, 500)

    this.element.querySelectorAll('.js-tab').forEach(tab => {
      tab.addEventListener('click', evt => {
        evt.preventDefault()
        this.openTab(tab, true)
      })
    })
  }

  openTab (tab, andAddState) {
    const nextPageId = tab.getAttribute('href')
    const nextPage = document.getElementById(nextPageId)

    if (nextPage === this.activePage) {
      return
    }

    this.activePage.classList.add('is-hidden')
    nextPage.classList.remove('is-hidden')

    this.activeTab.classList.remove(ACTIVE_TAB_CLASS)
    tab.classList.add(ACTIVE_TAB_CLASS)

    document.documentElement.scrollTop = 0
    setTimeout(() => {
      this.scrollToHashFragment()
    }, 0)

    this.activeTab = tab
    this.activePage = nextPage

    if (andAddState) {
      this.navigationState.addState(nextPageId)
    }
  }

  scrollToHashFragment () {
    if (document.location.hash.length > 0) {
      // try to find selector by id in this tab
      const hashElement = this.activePage.querySelector(`[id="${document.location.hash.substr(1)}"]`)
      if (hashElement) {
        hashElement.scrollIntoView()
      }
    }
  }
}
