<template>
  <component :is="heading" class="labeled-title">
    <span v-if="label" class="labeled-title__label">{{ label }}</span>
    <span class="labeled-title__text">{{ text }}</span>
  </component>
</template>

<script>
export default {
  props: {
    heading: String,
    label: String,
    text: String
  }
}
</script>
