var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.disableRedesign === false
    ? _c("a", { attrs: { href: "#" }, on: { click: _vm.onClick } }, [
        _c("span", [
          _vm._v(
            _vm._s(
              _vm.disableRedesign ? "Oude omgeving" : "Vernieuwde omgeving"
            )
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }