var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("nav", { attrs: { "aria-label": "breadcrumb" } }, [
    _c(
      "ul",
      { staticClass: "breadcrumb mb-0" },
      _vm._l(_vm.items, function (item) {
        return _c(
          "li",
          { staticClass: "breadcrumb__item" },
          [
            _c(
              item.url ? "a" : "span",
              {
                tag: "component",
                staticClass:
                  "labeled-title labeled-title--small is-inline-flex m-0",
                attrs: { href: item.url },
              },
              [
                item.label
                  ? _c(
                      "span",
                      {
                        staticClass: "labeled-title__label",
                        class: _vm.labelClass,
                      },
                      [_vm._v(_vm._s(item.label))]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass:
                      "labeled-title__text labeled-title__text--bold labeled-title__text--sans-serif",
                  },
                  [_vm._v(_vm._s(item.text))]
                ),
              ]
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }