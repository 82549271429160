import { EventBus, INTERACTION_IMAGE_SCALED } from '../common/EventBus'

export default class ImageScaling {
  scaleImages (containerElement, strategy) {
    const images = containerElement.querySelectorAll('.js-scalable-image')
    if (images.length === 0) {
      EventBus.$emit(INTERACTION_IMAGE_SCALED)
      return
    }
    strategy.scaleImages(containerElement, images, this._setImageHeight)
    EventBus.$emit(INTERACTION_IMAGE_SCALED)
  }

  _setImageHeight (image, minHeight, calculateMaxHeight) {
    image.style.height = ''
    const imageRect = image.getBoundingClientRect()
    const imageHeight = imageRect.height
    if (imageHeight === 0 || imageHeight < minHeight) {
      return
    }
    const maxHeight = calculateMaxHeight(imageHeight)
    image.style.height = Math.max(Math.min(imageHeight, maxHeight), minHeight) + 'px'
  }
}
