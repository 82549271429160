<template>
  <modal
    @close="$emit('close')"
    :buttons="[
        { label: 'Sluiten', type: 'primary', handler: () => $emit('close') }
    ]"
    :close-button="true"
    :backdrop="true"
    :dismiss-via-backdrop="true"
    :title="title"
    :type="ModalType.condensed()">
    <p class="my-4" v-html="content"></p>
  </modal>
</template>

<script>
import Modal, { ModalType } from './Modal'

export default {
  components: {
    Modal
  },
  props: {
    title: String,
    content: String
  },
  data () {
    return {
      ModalType
    }
  }
}
</script>
