<template>
  <div
    class="bg-white right-0 w-px-3 bottom-0 top-0 absolute transition-opacity ease-out duration-300 pointer-events-none"
    :class="scrollbarOverlayClasses"></div>
</template>
<script>

export const scrollbarClasses = 'scrollbar scrollbar-thin scrollbar-thumb-gray-500 scrollbar-track-gray-300 scrollbar-thumb-rounded scrollbar-track-rounded'

export default {
  props: {
    show: Boolean,

  },
  computed: {
    scrollbarOverlayClasses () {
      return {
        'opacity-0': this.show
      }
    }
  },
}
</script>
