export default class {
  constructor(element) {
    this.el = element

    this.scrolling = false
    this.wrapperEl = document.querySelector('.js-theory-content')

    const navbar = document.getElementById('navbar')
    this.navbarHeight = navbar ? navbar.clientHeight : 0
    this.progress = 0
    this.isRedesign = window.activeLayout === 'redesign'

    // Get all sections that need to be in index and cache DOM elements
    this.sections = [...document.querySelectorAll('.js-reading-indicator-section')].map(el => {
      const id = el.getAttribute('data-section-id')
      const progressEl = this.el.querySelector(`.js-rpi-progress-element[data-section-id='${id}']`)

      return {
        el: el,
        progressEl: progressEl,
        progressMeter: progressEl.querySelector('.js-rpi-progress-meter')
      }
    })

    this.addScrollHandlers()
    this.addClickHandlers()

    this.updateIndicatorBars()
    this.updateIndicatorPositioning()

    window.addEventListener('resize', () => {
      this.updateIndicatorBars()
      this.updateIndicatorPositioning()
    })
  }

  updateIndicatorBars() {
    const scrollBottom = this.getScrollTop() + window.innerHeight
    this.unreadIndex = null
    let totalHeight = 0
    let progressHeight = 0

    this.sections.forEach((section, index) => {
      const sectionTop = section.el.getBoundingClientRect().top + window.scrollY
      const sectionHeight = section.el.clientHeight
      if (sectionHeight === 0) {
        return
      }
      const progressMeterHeight = section.progressEl.clientHeight

      totalHeight += progressMeterHeight
      if (sectionTop > scrollBottom) {
        if (null === this.unreadIndex) {
          this.unreadIndex = index
        }
      } else if (scrollBottom >= sectionTop && sectionTop + sectionHeight > scrollBottom) {
        const percentageDone = 100 * ((scrollBottom - sectionTop) / sectionHeight)
        section.progressMeter.style.height = `${percentageDone}%`
        section.progressEl.classList.remove('is-read')
        section.progressEl.classList.add('is-reading')
        progressHeight += ((progressMeterHeight * percentageDone) / 100)
      } else {
        section.progressEl.classList.add('is-read')
        section.progressMeter.style.height = '100%'
        progressHeight += progressMeterHeight
      }
    })

    if (null !== this.unreadIndex) {
      this.unreadSections(this.unreadIndex)
    }

    this.progress = totalHeight > 0 ? progressHeight / totalHeight : 0
  }

  unreadSections(fromIndex) {
    this.sections.forEach((section, index) => {
      if (index >= fromIndex) {
        section.progressEl.classList.remove('is-read')
        section.progressEl.classList.remove('is-reading')
        section.progressMeter.style.height = '0%'
      }
    })
  }

  addScrollHandlers() {
    window.addEventListener('scroll', evt => {
      if (!this.scrolling) {
        window.requestAnimationFrame(evt => {
          this.updateIndicatorBars()
          this.updateIndicatorPositioning()
          this.scrolling = false
        })
        this.scrolling = true
      }
    })
  }

  addClickHandlers() {
    this.el.querySelectorAll('.js-rpi-section-link').forEach(link => {
      link.addEventListener('click', evt => {
        evt.preventDefault();
        this.animateTo(evt.currentTarget.getAttribute('href').substring(1))
      })
    })
  }

  animateTo(id) {
    const anchor = this.getAnchor(id)
    if (null !== anchor) {
      anchor.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
    }
  }

  getAnchor(id) {
    const anchor = document.getElementById(id)
    const fixedAnchor = document.getElementById(id + '-fixed')
    if (null !== fixedAnchor && null !== document.querySelector('nav.tabs-nav-fixed')) {
      return fixedAnchor
    }
    return anchor
  }

  getScrollTop() {
    if ('number' == typeof(window.pageYOffset)) {
      //Netscape compliant
      return window.pageYOffset
    } else if (document.body && document.body.scrollTop) {
      //DOM compliant
      return document.body.scrollTop
    } else if (document.documentElement && document.documentElement.scrollTop) {
      //IE6 standards compliant mode
      return document.documentElement.scrollTop
    }
    return 0
  }

  updateIndicatorPositioning() {
    const scrollTop = this.getScrollTop()
    const wrapperTop = this.wrapperEl.offsetTop
    const fixedBreakpoint = (wrapperTop - this.navbarHeight)
    const isFixed = (scrollTop >= fixedBreakpoint && scrollTop > 0 && fixedBreakpoint > 0)
    const leftPos = this.wrapperEl.getBoundingClientRect().right;

    if (!isFixed) {
      this.el.style.marginTop = 0
      if(this.isRedesign) {
        this.el.style.left = null;
        this.el.classList.remove('fixed')
        this.el.classList.add('relative')
      } else {
        this.el.classList.remove('is-fixed')
      }
      return
    }

    // Fixed
    if(this.isRedesign) {
      this.el.style.left = leftPos + 'px';
      this.el.classList.remove('relative')
      this.el.classList.add('fixed')
    } else {
      this.el.classList.add('is-fixed')
    }
    const horizontalOverflow = this.el.clientHeight - (window.innerHeight - this.navbarHeight)
    if (horizontalOverflow > 0) {
      // We use this.progress, which is calculated in updateIndicatorBars. It uses the section height
      // to calculate real progress within the page.
      this.el.style.marginTop = Math.round((horizontalOverflow * this.progress) * -1) + 'px'
    } else {
      this.el.style.marginTop = 0
    }
  }
}
