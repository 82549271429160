class ScoreCategoryWithAssignments {
  constructor (scoreCategoryName) {
    this.name = scoreCategoryName

    this._mapping = {}
    this.paragraphChildren = []
  }

  addAssignment (paragraphChild, assignment) {
    if (this._mapping[paragraphChild.contentPath] === undefined) {
      const groupedParagraphChild = {
        title: paragraphChild.title,
        contentPath: paragraphChild.contentPath,
        assignments: []
      }
      this._mapping[paragraphChild.contentPath] = groupedParagraphChild
      this.paragraphChildren.push(groupedParagraphChild)
    }
    this._mapping[paragraphChild.contentPath].assignments.push(assignment)
  }
}

export default class GroupedAssignments {
  constructor (paragraphChildren, scoreCategories) {
    const mapping = {}
    this.scoreCategories = []
    for (const scoreCategory of scoreCategories) {
      const scoreCategoryWithAssignments = new ScoreCategoryWithAssignments(scoreCategory)
      mapping[scoreCategory] = scoreCategoryWithAssignments
      this.scoreCategories.push(scoreCategoryWithAssignments)
    }
    for (const paragraphChild of paragraphChildren) {
      for (const assignment of paragraphChild.assignments) {
        const category = assignment.averageScore.scoreCategoryName
        mapping[category].addAssignment(paragraphChild, assignment)
      }
    }
  }
}
