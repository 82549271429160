<template>
  <button class="button" :class="[ styleClass, sizeClass ]" :type="type" @click="click" @dblclick.prevent="" :disabled="disabledStatus">
    {{ buttonLabel }}<i class="ml-1" :class="[ buttonIcon ]" v-if="buttonIcon"></i>
  </button>
</template>

<script>
  export default {
    data () {
      return {
        alreadyClicked: false,
        disabledStatus: this.disabled || false
      }
    },

    props: {
      styleClass: {
        default: 'button--primary',
        type: String
      },
      sizeClass: {
        default: '',
        type: String
      },
      type: {
        default: 'submit',
        type: String
      },
      submitFormId: {
        default: '',
        type: String
      },
      buttonLabel: String,
      buttonIcon: {
        default: '',
        type: String
      },
      disabled: {
        default: false,

      }
    },

    methods: {
      click () {
        if (this.alreadyClicked) {
          this.disabledStatus = true
          return
        }
        this.alreadyClicked = true

        if ('' !== this.submitFormId) {
          document.getElementById(this.submitFormId).submit()
        }
      }
    }
  }
</script>
