var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "carousel glide" }, [
    _c(
      "div",
      {
        staticClass: "carousel__track glide__track",
        attrs: { "data-glide-el": "track" },
      },
      [
        _c(
          "ul",
          { staticClass: "carousel__slides glide__slides" },
          [_vm._t("slide")],
          2
        ),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.childCount > 1,
            expression: "childCount > 1",
          },
        ],
        staticClass: "carousel__controls glide__arrows",
        attrs: { "data-glide-el": "controls" },
      },
      [
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentSlide > 0,
                expression: "currentSlide > 0",
              },
            ],
            staticClass: "carousel__arrow carousel__arrow--left glide__arrow",
            class: _vm.controlsItemClasses,
            attrs: { "data-glide-dir": "<" },
          },
          [
            _c("i", {
              staticClass: "icon-tm-chevron-left",
              class: _vm.controlsIconClasses,
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "span",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.currentSlide < _vm.slideCount - 1,
                expression: "currentSlide < (slideCount - 1)",
              },
            ],
            staticClass: "carousel__arrow carousel__arrow--right glide__arrow",
            class: _vm.controlsItemClasses,
            attrs: { "data-glide-dir": ">" },
          },
          [
            _c("i", {
              staticClass: "icon-tm-chevron-right",
              class: _vm.controlsIconClasses,
            }),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }