var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(_vm.heading, { tag: "component", staticClass: "labeled-title" }, [
    _vm.label
      ? _c("span", { staticClass: "labeled-title__label" }, [
          _vm._v(_vm._s(_vm.label)),
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("span", { staticClass: "labeled-title__text" }, [
      _vm._v(_vm._s(_vm.text)),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }