<template>
  <glide-carousel class="carousel--align-top" :carousel="true">
    <glide-carousel-slide v-for="category in groupedStudents.scoreCategories" :key="category.name" slot="slide">
      <div class="progress-modal-slide px-7 pt-1">
        <div :class="`text-align-center score-color score-color--${category.name}`"><strong>{{ $t('generic.score_category.' + category.name) }}</strong></div>
        <div v-for="student in category.students" :key="student.userReference">{{student.fullName}}</div>
      </div>
    </glide-carousel-slide>
  </glide-carousel>
</template>

<script>

import GlideCarousel from '../../carousel/GlideCarousel'
import GlideCarouselSlide from '../../carousel/GlideCarouselSlide'
import GroupedStudents from './GroupedStudents'

export default {
  components: {
    GlideCarousel,
    GlideCarouselSlide
  },
  props: {
    groupedStudents: GroupedStudents
  }
}
</script>
