var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "w-screen h-16 transform transition-transform ease-out duration-500 bg-red-dark bg-opacity-70 flex justify-center gap-x-2 items-center text-white font-bold text-lg fixed bottom-0 px-5",
      class: _vm.transformClass,
    },
    [
      _c("div", [
        _c("span", {
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("component.preferred_stream.notification_redesign")
            ),
          },
        }),
        _vm._v(" "),
        _c("a", {
          staticClass: "underline text-white hover:text-red-extra-light",
          attrs: { href: _vm.url },
          domProps: {
            innerHTML: _vm._s(
              _vm.$t("component.preferred_stream.notification_link_redesign")
            ),
          },
        }),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }