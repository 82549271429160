var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "button",
      class: [_vm.styleClass, _vm.sizeClass],
      attrs: { type: _vm.type, disabled: _vm.disabledStatus },
      on: {
        click: _vm.click,
        dblclick: function ($event) {
          $event.preventDefault()
        },
      },
    },
    [
      _vm._v("\n  " + _vm._s(_vm.buttonLabel)),
      _vm.buttonIcon
        ? _c("i", { staticClass: "ml-1", class: [_vm.buttonIcon] })
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }