<template>
  <div class="carousel glide">
    <div class="carousel__track glide__track" data-glide-el="track">
      <ul class="carousel__slides glide__slides">
        <slot name="slide"></slot>
      </ul>
    </div>
    <div class="carousel__controls glide__arrows" data-glide-el="controls" v-show="childCount > 1">
      <span v-show="currentSlide > 0" class="carousel__arrow carousel__arrow--left glide__arrow" :class="controlsItemClasses" data-glide-dir="<">
        <i class="icon-tm-chevron-left" :class="controlsIconClasses"></i>
      </span>
      <span v-show="currentSlide < (slideCount - 1)" class="carousel__arrow carousel__arrow--right glide__arrow" :class="controlsItemClasses" data-glide-dir=">">
        <i class="icon-tm-chevron-right" :class="controlsIconClasses"></i>
      </span>
    </div>
  </div>
</template>

<script>
import Glide from '@glidejs/glide'
import { GLIDE_CAROUSEL_MOVE, GLIDE_CAROUSEL_NAVIGATE, EventBus } from '../../common/EventBus'

export default {
  data: function () {
    return {
      childCount: null,
      currentSlide: this.startAt,
      slideCount: 0
    }
  },
  props: {
    startAt: {
      type: Number,
      default: 0
    },
    controlsItemClasses: {
      type: String,
      default: ''
    },
    controlsIconClasses: {
      type: String,
      default: ''
    },
    carousel: {
      type: Boolean,
      default: false
    }
  },
  glideInstance: null,
  mounted () {
    this.init()
    this.childCount = this.$children.length
  },
  beforeDestroy () {
    if (this.$options.glideInstance) {
      this.$options.glideInstance.destroy()
      this.$options.glideInstance = null
    }
  },
  methods: {
    init () {
      this.slideCount = this.$slots.slide.length

      const options = {
        type: this.carousel ? 'carousel' : 'slider',
        dragThreshold: 50,
        swipeThreshold: 50,
        perView: 1,
        perTouch: 1,
        gap: 0,
        rewind: false,
        keyboard: false,
        startAt: this.startAt
      }
      const glide = new Glide(this.$el, options)
      glide.on(['move'], () => {
        this.currentSlide = this.$options.glideInstance.index
        const element = this.$slots.slide[this.$options.glideInstance.index].elm.childNodes[0]
        EventBus.$emit(GLIDE_CAROUSEL_MOVE, element.dataset.originalParentReference)
      })
      this.$options.glideInstance = glide
      glide.mount()

      EventBus.$on(GLIDE_CAROUSEL_NAVIGATE, reference => {
        this.glideTo(reference)
      })

      this.$nextTick(() => {
        if (this.$options.glideInstance) {
          this.$options.glideInstance.update()
        }
      })
    },
    glideTo (reference) {
      this.$slots.slide.forEach((slide, index) => {
        const element = slide.elm.childNodes[0]
        if (reference === element.dataset.originalParentReference) {
          this.$options.glideInstance.go('=' + index)
        }
      })
    }
  }
}
</script>
