var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: _vm.identifier } }, [
    _c(
      "ul",
      {
        staticClass: "tabs",
        class: [_vm.tabsAdditionalClass, { "tabs--bordered": _vm.bordered }],
      },
      _vm._l(_vm.tabs, function (tab) {
        return _c("li", { staticClass: "tabs__item" }, [
          _c(
            "a",
            {
              staticClass: "tabs__link",
              class: _vm.applyActiveClass(tab),
              on: {
                click: function ($event) {
                  return _vm.selectTab(tab)
                },
              },
            },
            [
              _c(
                "span",
                { class: { "tabs__truncated-content": _vm.truncate } },
                [_vm._v(_vm._s(tab.name))]
              ),
              _vm._v(" "),
              _vm._t("tab-item-inner", null, { subdomain: tab }),
            ],
            2
          ),
        ])
      }),
      0
    ),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "tab-pages",
        class: [_vm.pageWrapperClass, { "tab-pages--animated": _vm.animated }],
      },
      [_vm._t("default")],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }