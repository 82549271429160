class ScoreCategoryStudents {
  constructor (scoreCategoryName) {
    this.name = scoreCategoryName
    this.students = []
  }

  addStudent (student) {
    this.students.push(student)
  }
}

export default class GroupedStudents {
  constructor (results, scoreCategories) {
    const mapping = {}
    this.scoreCategories = []
    for (const scoreCategory of scoreCategories) {
      const scoreCategoryStudents = new ScoreCategoryStudents(scoreCategory)
      mapping[scoreCategory] = scoreCategoryStudents
      this.scoreCategories.push(scoreCategoryStudents)
    }
    for (const result of results) {
      const category = result.averageScore.scoreCategoryName
      mapping[category].addStudent(result.student)
    }
  }
}
