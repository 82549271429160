'use strict'

export default class NavigationState {
  constructor (url, popStateCallback) {
    this.url = url

    window.addEventListener('popstate', (event) => {
      const state = event.state || {}
      const url = state.url || this.url
      popStateCallback(url)
    })
  }

  addState (url) {
    window.history.pushState({ url: url }, '', url)
  }
}
