<template>
  <component :is="useRedesign ? 'redesign-modal' : 'modal'" :type="ModalType.emptyCondensed()" :loading="isLoading" v-if="isOpen" @close="close" class="modal--notifications">
    <div v-if="useRedesign" class="flex flex-col overflow-auto relative js-focus" tabindex="0" @keydown="onKeydown" @mouseenter="showScrollbar = true" @mouseleave="showScrollbar = false">
      <loading-bar v-if="isLoading"></loading-bar>
      <div class="px-4"><div class="py-4 font-bold border-b border-gray-500">{{ $t('component.notifications.title') }}</div></div>
      <div class="relative flex flex-col overflow-hidden mr-1">
        <div class="overflow-auto flex-1 pl-4 pr-3 -mb-px" :class="scrollbarClasses">
          <notification
            v-for="(notification, index) in notifications"
            ref="notifications"
            :key="notification.id"
            :index="index"
            :notification="notification"
            :show-method="showMethod"
            :is-preview="false"
            :use-redesign="useRedesign"
            v-on:markRead="markNotificationAsRead"
            v-on:delete="deleteNotification"
            v-on:click="markNotificationAsReadAndGotoSubject"
          ></notification>
          <div v-show="!hideShowMore">
            <a ref="showMoreResults" class="block py-2 border-b border-gray-500 cursor-pointer" tabindex="0" @click.prevent="showMoreResults" @keydown.enter.prevent="showMoreResultsAndRefocus">{{ $t(isLoading ? 'component.notifications.loading' : 'component.notifications.view_more') }}</a>
          </div>
          <div v-if="didInitialLoad && notifications.length === 0" class="p-4">{{ $t('component.notifications.no_notifications') }}</div>
        </div>
        <scrollbar-overlay :show="showScrollbar"></scrollbar-overlay>
      </div>
      <div class="px-4"><a class="block font-bold py-2 border-t border-gray-500 cursor-pointer" tabindex="0" @click.prevent="readAll" @keydown.enter.prevent="readAll"><span v-html="$t('component.notifications.read_all')"></span></a></div>
    </div>
    <template v-else>
      <span class="notifications__title">{{ $t('component.notifications.title') }}</span>
      <div class="notifications">
        <notification
          v-for="(notification, index) in notifications"
          :key="notification.id"
          :index="index"
          :notification="notification"
          :show-method="showMethod"
          :is-preview="false"
          :use-redesign="useRedesign"
          v-on:markRead="markNotificationAsRead"
          v-on:delete="deleteNotification"
          v-on:click="markNotificationAsReadAndGotoSubject"
        ></notification>
        <span class="notifications__observer" ref="showMoreResults"></span>
        <div v-if="didInitialLoad && notifications.length === 0" class="notifications__empty">{{ $t('component.notifications.no_notifications') }}</div>
      </div>
      <a class="notifications__read-all" @click.prevent="readAll()"><i class="icon-check"></i><span v-html="$t('component.notifications.read_all')"></span></a>
    </template>
  </component>
</template>

<script>
  import { EventBus, NOTIFICATIONS_MODAL_OPEN_EVENT } from '../../common/EventBus'
  import NotificationService from '../notifications/NotificationService'
  import Notification from '../notifications/Notification'
  import Routing from '../../util/routing'
  import Modal from "../modal/Modal";

  import RedesignModal, { ModalType } from '../../redesign/modal/Modal'
  import LoadingBar from '../../redesign/LoadingBar'
  import ScrollbarOverlay, {scrollbarClasses} from '../../redesign/ScrollbarOverlay'

  export default {
    components: {
      Notification,
      Modal,
      RedesignModal,
      LoadingBar,
      ScrollbarOverlay
    },
    props: {
      useRedesign: Boolean
    },
    data() {
      return {
        isOpen: false,
        isLoading: false,
        notificationService: null,
        notifications: [],
        didInitialLoad: false,
        lastKeydown: new Date(),
        hideShowMore: false,
        ModalType,
        scrollbarClasses,
        showScrollbar: false
      }
    },
    computed: {
      showMethod() {
        if (this.notificationService instanceof NotificationService) {
          return !this.notificationService.hasMethod()
        }
        return false
      }
    },
    created() {
      EventBus.$on(NOTIFICATIONS_MODAL_OPEN_EVENT, (notificationService) => {
        this.notificationService = notificationService
        this.open()
      })
    },
    methods: {
      async open() {
        this.isOpen = true
        this.setupObserver()

        let observeShowMore = true
        if (!this.didInitialLoad) {
          observeShowMore = await this.showMoreResults()
          this.didInitialLoad = true
        }
        if (observeShowMore) {
          this.hideShowMore = false
        }

        await this.$nextTick()
        if (observeShowMore) {
          this.$options.showMoreObserver.observe(this.$refs.showMoreResults)
        }
        const focusElement = this.$el.querySelector('.js-focus')
        focusElement.focus()
        focusElement.blur()
      },
      close () {
        this.$options.showMoreObserver.disconnect()
        this.isOpen = false
      },
      readAll() {
        this.notificationService.markAllNotificationsAsRead()
      },
      markNotificationAsRead(notification) {
        this.notificationService.markNotificationAsRead(notification)
      },
      deleteNotification(notification) {
        this.notificationService.deleteNotification(notification)
      },
      async markNotificationAsReadAndGotoSubject(notification) {
        await this.notificationService.markNotificationAsRead(notification)
        window.location.href = Routing.generate(notification.route.name, notification.route.params)
      },
      async showMoreResultsAndRefocus() {
        if (this.isLoading) {
          return
        }
        const lastNotificationIndex = this.$refs.notifications.length - 1
        if (lastNotificationIndex !== -1) {
          const focusElements = this.$refs.notifications[lastNotificationIndex].$el.querySelectorAll('[tabindex="0"]')
          if (focusElements.length > 0) {
            const focusElement = focusElements[focusElements.length - 1]
            focusElement.focus()
            focusElement.blur()
          }
        }
        await this.showMoreResults()
      },
      async showMoreResults () {
        if (this.isLoading) {
          return
        }
        this.isLoading = true
        const { notifications, added } = await this.notificationService.loadNextNotifications()
        if (!added) {
          this.hideShowMore = true
          this.$options.showMoreObserver.disconnect()
        }
        this.notifications = notifications
        this.isLoading = false
        return added
      },
      onKeydown() {
        this.lastKeydown = new Date()
      },
      setupObserver: function () {
        if (this.$options.showMoreObserver instanceof IntersectionObserver) {
          return
        }
        this.$options.showMoreObserver = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && (new Date().getTime() - this.lastKeydown.getTime()) > 100) {
            this.showMoreResults()
          }
        }, {
          rootMargin: '0px 0px 50px 0px',
          threshold: 0
        })
      }
    }
  }
</script>
