var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {
    staticClass:
      "bg-white right-0 w-px-3 bottom-0 top-0 absolute transition-opacity ease-out duration-300 pointer-events-none",
    class: _vm.scrollbarOverlayClasses,
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }