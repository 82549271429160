var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "glide-carousel",
    { staticClass: "carousel--align-top", attrs: { carousel: true } },
    _vm._l(_vm.groupedAssignments.scoreCategories, function (category) {
      return _c(
        "glide-carousel-slide",
        { key: category.name, attrs: { slot: "slide" }, slot: "slide" },
        [
          _c(
            "div",
            { staticClass: "progress-modal-slide px-7 pt-1" },
            [
              _c(
                "div",
                {
                  class: `text-align-center score-color score-color--${category.name}`,
                },
                [
                  _c("strong", [
                    _vm._v(
                      _vm._s(_vm.$t("generic.score_category." + category.name))
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _vm._l(category.paragraphChildren, function (paragraphChild) {
                return _c(
                  "div",
                  {
                    key: paragraphChild.contentPath + category.name,
                    staticClass: "paragraph-item paragraph-item--full-width",
                  },
                  [
                    _c("header", { staticClass: "paragraph-item__header" }, [
                      _c("h1", { staticClass: "h6 paragraph-item__title" }, [
                        _c(
                          "span",
                          { staticClass: "paragraph-item__title-text" },
                          [_vm._v(_vm._s(paragraphChild.title))]
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("footer", { staticClass: "paragraph-item__footer" }, [
                      _c(
                        "div",
                        { staticClass: "paragraph-item__questions" },
                        _vm._l(
                          paragraphChild.assignments,
                          function (assignment) {
                            return _c(
                              "span",
                              {
                                key: assignment.contentPath,
                                staticClass:
                                  "paragraph-item__question-container",
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    class: `question-label question-label--type-${assignment.scoreType}`,
                                  },
                                  [_vm._v(_vm._s(assignment.number))]
                                ),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]),
                  ]
                )
              }),
            ],
            2
          ),
        ]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }