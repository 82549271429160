var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isOpen
    ? _c(
        "modal",
        {
          attrs: { loading: _vm.loading, "close-button-inside": true },
          on: { close: _vm.close },
        },
        [
          _c(
            "div",
            { staticClass: "p-3 has-border-bottom" },
            [
              _c("labeled-title", {
                staticClass: "mb-0",
                attrs: {
                  heading: "h5",
                  text: _vm.$t("component.progress.modal.title"),
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.loading
            ? _c("div", { staticClass: "p-3" }, [_vm._v("\n    ...\n  ")])
            : [
                _c("breadcrumbs", {
                  staticClass: "p-3",
                  attrs: {
                    "chapter-position": _vm.breadcrumbs.chapterPosition,
                    items: _vm.breadcrumbs.items,
                  },
                }),
                _vm._v(" "),
                _c(
                  "tabs",
                  {
                    attrs: {
                      bordered: true,
                      animated: false,
                      "tabs-additional-class": "mx-3",
                    },
                  },
                  [
                    _c(
                      "tab",
                      {
                        attrs: {
                          name: _vm.$t("generic.term.exercises"),
                          uid: 1,
                          active: true,
                          "render-hidden": false,
                        },
                      },
                      [
                        _c("assignments", {
                          attrs: {
                            "grouped-assignments": _vm.groupedAssignments,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "tab",
                      {
                        attrs: {
                          name: _vm.$t("generic.term.students"),
                          uid: 2,
                          "render-hidden": false,
                        },
                      },
                      [
                        _c("students", {
                          attrs: { "grouped-students": _vm.groupedStudents },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }