<template>
  <glide-carousel class="carousel--align-top" :carousel="true">
    <glide-carousel-slide v-for="category in groupedAssignments.scoreCategories" :key="category.name" slot="slide">
      <div class="progress-modal-slide px-7 pt-1">
        <div :class="`text-align-center score-color score-color--${category.name}`"><strong>{{ $t('generic.score_category.' + category.name) }}</strong></div>
        <div class="paragraph-item paragraph-item--full-width" v-for="paragraphChild in category.paragraphChildren" :key="paragraphChild.contentPath + category.name">
          <header class="paragraph-item__header">
            <h1 class="h6 paragraph-item__title">
              <span class="paragraph-item__title-text">{{ paragraphChild.title }}</span>
            </h1>
          </header>
          <footer class="paragraph-item__footer">
            <div class="paragraph-item__questions">
              <span class="paragraph-item__question-container" v-for="assignment in paragraphChild.assignments" :key="assignment.contentPath">
                <span :class="`question-label question-label--type-${assignment.scoreType}`">{{ assignment.number }}</span>
              </span>
            </div>
          </footer>
        </div>
      </div>
    </glide-carousel-slide>
  </glide-carousel>
</template>

<script>

import GlideCarousel from "../../carousel/GlideCarousel"
import GlideCarouselSlide from "../../carousel/GlideCarouselSlide"
import GroupedAssignments from "./GroupedAssignments";

export default {
  components: {
    GlideCarousel,
    GlideCarouselSlide
  },
  props: {
    groupedAssignments: GroupedAssignments
  }
}
</script>
