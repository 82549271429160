<template>
    <a href="#" @click="onClick" v-if="disableRedesign === false"><span>{{disableRedesign ? 'Oude omgeving' : 'Vernieuwde omgeving'}}</span></a>
</template>
<script>
import { routePost } from '../util/request'
import { EventBus, TOOL_USE_EVENT } from '../common/EventBus'

export default {
  props: {
    token: String,
    disableRedesign: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    async onClick () {
      EventBus.$emit(TOOL_USE_EVENT, document.location.href, 'Redesign', this.disableRedesign ? 'off' : 'on')
      await routePost('user_setting_set_use_redesign', {}, {
        _token: this.token,
        value: this.disableRedesign ? 'false' : 'true'
      })
      document.location.reload()
    }
  }
}
</script>
