<template>
  <modal-dialog
    v-if="!redesign && unseenAnnouncements[announcementIndex]"
    v-on:close="onClose"
    :show-close-button="true"
    :title="unseenAnnouncements[announcementIndex].title"
    close-type="primary"
    :primary-action-label="buttonLabel"
  >
    <div slot="content" v-html="unseenAnnouncements[announcementIndex].message"></div>
  </modal-dialog>
  <info-dialog
    v-else-if="redesign && unseenAnnouncements[announcementIndex]"
    :title="unseenAnnouncements[announcementIndex].title"
    :content="unseenAnnouncements[announcementIndex].message"
    @close="onClose"
  ></info-dialog>
</template>

<script>
  import ModalDialog from './modal/ModalDialog'
  import InfoDialog from '../redesign/modal/InfoDialog'

  const MAINTENANCE_ANNOUNCEMENT_COOKIE = 'maintenance_announcement_seen';
  const createCookieString = (announcement, userHash) => {
    return MAINTENANCE_ANNOUNCEMENT_COOKIE + '_' + userHash + '_' + announcement.id
  }

  export default {
    components: {
      ModalDialog, InfoDialog
    },
    data() {
      const unseenAnnouncements = [];
      for (const announcement of this.announcements) {
        if (this.isBrowser(announcement.userAgent) && this.isUnseen(announcement)) {
          unseenAnnouncements.push(announcement)
        }
      }

      return {
        unseenAnnouncements: unseenAnnouncements,
        announcementIndex: 0
      }
    },
    props: {
      buttonLabel: String,
      userHash: String,
      announcements: Array,
      redesign: Boolean
    },
    methods: {
      isBrowser(userAgent) {
        switch (userAgent) {
          // Keys as defined in USER_AGENTS constant in UserAgent.php.
          // Logic for browser detection source:
          // https://stackoverflow.com/questions/9847580/how-to-detect-safari-chrome-ie-firefox-and-opera-browser
          case 'ie': return !!document.documentMode; // true for IE 8-11
          case 'old-opera': return !!window.opera; // true for opera based on Presto engine, until Opera 12.18
          case 'firefox': return typeof InstallTrigger !== 'undefined'; // true for ALL firefox versions
          case 'old-edge': return !window.chrome && !document.documentMode && !!window.StyleMedia; // true for Microsoft Edge versions not based on chromium
          default: return true; // fallback for "all"
        }
      },
      isUnseen(announcement) {
        return document.cookie.indexOf(createCookieString(announcement, this.userHash)) === -1;
      },
      onClose() {
        const announcement = this.unseenAnnouncements[this.announcementIndex]
        const cookieString = createCookieString(announcement, this.userHash)
        document.cookie = `${cookieString}=;path=/;expires=${announcement.showUntil}`;

        this.announcementIndex++
      }
    }
  }
</script>
