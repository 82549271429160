import $ from 'jquery' // TODO remove jQuery

export default class ScrollSpy {
    static get ELEMENT_SPY() { return 'edition-scroll-spy' };
    static get ELEMENT_SPY_CONTENT() { return 'edition-scroll-spy-content' };
    static get ELEMENT_SPY_INDEX() { return 'edition-scroll-spy-index' };
    static get ELEMENT_SPY_ANCHOR() { return 'edition-scroll-spy-anchor' };
    static get ELEMENT_SPY_ITEM() { return 'edition-scroll-spy-item' };
    static get ELEMENT_SPY_ACTIVE_CLASS() { return 'edition-scroll-spy-active-class' };

    constructor(element) {
        this.isAutoScrolling = false;
        this.topThreshold = 80;

        this.element = element;

        this.contentElement = $(element).find('[' + ScrollSpy.ELEMENT_SPY_CONTENT + ']');
        this.indexElement = $(element).find('[' + ScrollSpy.ELEMENT_SPY_INDEX + ']');
        this.anchorElements = $(element).find('[' + ScrollSpy.ELEMENT_SPY_ANCHOR + ']');

        this.scrollToAnchor(this.getAnchorFromHash(window.location.hash));

        this.bindScrollEvents();
        this.bindClickEvents();
    }

    bindScrollEvents() {
        this.contentElement.on('scroll', this.onScroll.bind(this));
    }

    bindClickEvents() {
        $(this.element).find('[' + ScrollSpy.ELEMENT_SPY_ITEM + ']').click(this.onSpyItemClick.bind(this));
    }

    onScroll() {
        if (!this.isAutoScrolling) {
            let self = this;

            window.requestAnimationFrame(function() {
                self.selectIndexElem(self.getIndexFromScrollOffset(self.contentElement[0].scrollTop));
                self.isAutoScrolling = false;
            });

            this.isAutoScrolling = true;
        }
    }

    onSpyItemClick(event) {
        event.preventDefault();
        this.scrollToAnchor(this.getAnchorFromIndex($(event.target)));
    }

    getAnchorFromHash(hash) {
        let anchorElem = $(this.element).find('[' + ScrollSpy.ELEMENT_SPY_ANCHOR + '="' + String(hash).substr(1) + '"]');

        return anchorElem && anchorElem.length > 0 ? anchorElem : $(this.element).find('[' + ScrollSpy.ELEMENT_SPY_ANCHOR + ']').first();
    }

    getAnchorFromIndex(indexElem) {
        return $(this.element).find('[' + ScrollSpy.ELEMENT_SPY_ANCHOR + '="' + indexElem.attr(ScrollSpy.ELEMENT_SPY_ITEM) + '"]');
    }

    getIndexFromAnchor(anchorElem) {
        return $(this.element).find('[' + ScrollSpy.ELEMENT_SPY_ITEM + '="' + anchorElem.attr(ScrollSpy.ELEMENT_SPY_ANCHOR) + '"]');
    }

    getActiveClassFromElement(element) {
        return element.attr(ScrollSpy.ELEMENT_SPY_ACTIVE_CLASS);
    }

    getIndexFromScrollOffset(offset) {
        let i = 0;

        offset = offset < 0 ? 0 : offset; // normalize because of bouncy scrolling

        for (; i < this.anchorElements.length; i++) {
            if ((this.anchorElements[i].offsetTop - this.contentElement[0].offsetTop) - this.topThreshold > offset) {
                break;
            }
        }

        return this.getIndexFromAnchor($(this.anchorElements[i - 1]));
    }

    selectIndexElem(indexElem) {
        if (this.currentIndexElem && this.currentIndexElem.is(indexElem)) {
            return;
        }

        if (this.currentIndexElem) {
            this.currentIndexElem.removeClass(this.getActiveClassFromElement(this.currentIndexElem));
        }

        this.currentIndexElem = indexElem.addClass(this.getActiveClassFromElement(indexElem));

        // if the top of indexElem disappears above the top of the index
        if (indexElem[0].offsetTop < this.indexElement[0].scrollTop) {
            this.indexElement[0].scrollTop = indexElem[0].offsetTop;
        }
        // if the bottom (+40) of indexElem disappears below the bottom of the index
        else if (indexElem[0].offsetTop + 40 > this.indexElement[0].offsetHeight + this.indexElement[0].scrollTop) {
            this.indexElement[0].scrollTop = indexElem[0].offsetTop + 40 - this.indexElement[0].offsetHeight;
        }

        if (history && history.replaceState) {
            history.replaceState({}, '', indexElem[0].href);
        }
    }

    scrollToAnchor(anchorElem) {
        if (!this.isAutoScrolling) {
            let self = this;

            window.requestAnimationFrame(function() {
                self.contentElement.animate({ 'scrollTop': anchorElem[0].offsetTop - self.contentElement[0].offsetTop }, 250, function() {
                    window.requestAnimationFrame(function() {
                        self.selectIndexElem(self.getIndexFromAnchor(anchorElem));
                        self.isAutoScrolling = false;
                    });
                });
            });

            this.isAutoScrolling = true;
        }
    }
}
