import '@babel/polyfill'
import '@webcomponents/webcomponentsjs/webcomponents-bundle'

// Web components (https://github.com/Thiememeulenhoff/frontend)
import '@thiememeulenhoff/albert';
import '@thiememeulenhoff/content';
// End web components
import Routing from './util/routing'

import * as Sentry from "@sentry/vue";
import Vue from 'vue'
import VueTranslator from './common/VueTranslator'

import GuidedTour from './components/tour/GuidedTour'
import ProgressButton from './components/progress/ProgressButton'
import ProgressModal from './components/progress/ProgressModal'
import NotificationsIcon from './components/notifications/NotificationsIcon'
import NotificationsModal from './components/notifications/NotificationsModal'
import NavSearchButton from './components/search/NavSearchButton'
import bindElements from './util/bind-elements'
import {setupContentElements} from './util/setup-content-elements'
import setupMaintenanceAnnouncement from './util/setup-maintenance-announcement'
import CrossLink from './components/CrossLink'
import ReadingProgressIndicator from './components/ReadingProgressIndicator'
import ScrollSpy from './components/ScrollSpy'
import Tabs from './components/Tabs'
import NotificationToaster from './components/Notification-IziToast'
import TableOfContents from './components/TableOfContents'
import TheoryPageHeader from './components/TheoryPageHeader'

import ProgressBar from './components/ProgressBar.vue'
import ModalDialogTrigger from './components/ModalDialogTrigger.vue'
import ClickOnceButton from './components/ClickOnceButton.vue'
import SelectGroupForStream from './components/SelectGroupForStream.vue'
import Dropdown from './components/Dropdown.vue'
import UseRedesignNavItem from './components/UseRedesignNavItem.vue'
import PreferredStreamNotification from './components/PreferredStreamNotification'

import ToolButton from './components/ToolButton'
import NavbarManager from './components/Navbar.js'
import {ThasServiceFactory} from './thas/ThasService'

import InlineAudioButton from './components/inline-audio/InlineAudioButton'
import ShareMenu from './components/share/ShareMenu'

import {ContainerHeightStrategy, HalfWindowHeightStrategy, imageScalingService} from './components/ImageScalingService'
import '../style/style.scss'
import WindowResizeEventEmitter from './common/WindowResizeEventEmitter'
import {GoogleAnalyticsServiceFactory} from './util/GoogleAnalyticsService'

require('./util/polyfills.js')

if (module.hot) {
  module.hot.accept()
}

Vue.use(VueTranslator)

window.jQuery = window.$ = require('../scripts/lib/jquery-2.1.1')

if (window.sentryConf !== undefined && 'dev' !== window.sentryConf.environment) {
  Sentry.init({
    Vue,
    dsn: "https://363ac387c6764b9c92274c326523e64e@o1127103.ingest.sentry.io/4504792465473536",
    environment: window.sentryConf.environment,
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications.',
      'Identifier \'change_ua\' has already been declared'
    ],
    trackComponents: true,
  });
}

export const windowResizeEmitter = new WindowResizeEventEmitter()

document.addEventListener('DOMContentLoaded', function () {
  const baseApp = document.getElementById('base-app');
  if (baseApp !== null) {
    new Vue({ // eslint-disable-line no-new
      el: baseApp,
      components: { ModalDialogTrigger, ProgressBar, ClickOnceButton, SelectGroupForStream, Dropdown, ProgressButton },
    })
  }

  const modalsApp = document.getElementById('modals-app');
  if (modalsApp !== null) {
    new Vue({ // eslint-disable-line no-new
      el: modalsApp,
      components: { NotificationsModal, ProgressModal },
    })
  }

  const toastApp = document.getElementById('toast-app');
  if (toastApp !== null) {
    new Vue({ // eslint-disable-line no-new
      el: toastApp,
      components: { PreferredStreamNotification },
    })
  }

  const theoryApp = document.getElementById('theory-app')
  if (theoryApp !== null) {
    new Vue({ // eslint-disable-line no-new
      el: theoryApp,
      components: { InlineAudioButton, ShareMenu }
    })
  }

  // ProgressBar and ClickOnceButton are only present in summonlist mode
  const navbarElement = document.getElementById('navbar')
  if (navbarElement !== null) {
    new Vue({ // eslint-disable-line no-new
      el: navbarElement,
      components: {
        GuidedTour,
        NotificationsIcon,
        ProgressBar,
        ClickOnceButton,
        ModalDialogTrigger,
        NavSearchButton,
        UseRedesignNavItem
      },
    })
  }

  setupMaintenanceAnnouncement()

  setupElements(document);
});

export function setupElements (elem) {
  const defaultOptions = {
    redesign: 'redesign' === window.activeLayout
  }

  window.navbarManager = new NavbarManager();

  setupContentElements(elem)

  bindElements(elem, '.js-reading-progress-indicator', ReadingProgressIndicator)

  bindElements(elem, '.js-tabs', Tabs)

  elem.querySelectorAll('.js-theory-tabs').forEach(element => {
    const tabPages = elem.querySelector('.js-tab-pages')
    if (tabPages) {
      const updatePaddingTop = () => {
        tabPages.style.paddingTop = element.offsetHeight + 'px'
      }
      window.addEventListener('resize', updatePaddingTop)
      updatePaddingTop()
    }
  })

  // Cross links coming from web component
  document.addEventListener('xrefClicked', (event) => {
    if (window.msp === undefined || window.msp.streamCode === '') {
      throw Error('No stream data defined in global namespace!')
    }

    const url = Routing.generate('crosslink_target_stream_code', {
      streamCode: window.msp.streamCode,
      contentReference: event.detail.id,
      crosslinkScope: 'theory'
    })

    window.location.href = url;
  });

  // Embed breadcrumb coming from web component
  document.addEventListener('breadcrumbsItemClicked', (event) => {
    function stripThipPrefix(string) {
      if (!string) return null;
      return String(string).replace('thip.', '');
    }

    const contentIdentifier = stripThipPrefix(event.detail.contentIdentifier);
    const parentContentPath = stripThipPrefix(event.detail.parentContentPath);
    let url, routePath, routeAdd;

    if (parentContentPath) {
      routePath = parentContentPath
      routeAdd = `#${contentIdentifier}`
    } else {
      routePath = contentIdentifier
      routeAdd = ''
    }

    if (null === routePath || '' === routePath) {
      return;
    }

    let data = {
      streamCode: window.msp.streamCode,
      contentPath: routePath
    }

    if (true === window.msp.isReferenceMaterial) {
      url = Routing.generate('book_chapter', data) + routeAdd
    } else {
      url = Routing.generate('content_reference_chapter', data) + routeAdd
    }

    window.location.href = url
  });

  // Crosslinks on the question page are loaded in split screen
  if (document.getElementById('question-app') === null) {
    bindElements(elem, '.js-crosslink', CrossLink, {
      crosslinkScope: 'theory',
      onClick: (url, element, target) => {
        if ('_blank' === target) {
          window.open(url, target)
        } else {
          window.location.href = url
        }
      }
    })

    // setup image scaling on page level
    imageScalingService.add('.js-page', window.activeLayout === 'redesign' ? new HalfWindowHeightStrategy() : new ContainerHeightStrategy(100))
  }

  bindElements(elem, '[' + ScrollSpy.ELEMENT_SPY + ']', ScrollSpy);

  bindElements(elem, '.js-notification', NotificationToaster, defaultOptions)

  bindElements(elem, '.js-toc', TableOfContents)

  bindElements(elem, '.js-theory-page-header', TheoryPageHeader)

  bindElements(elem, '.js-tool-button', ToolButton)
}

if (window.thasConfiguration !== undefined) {
  ThasServiceFactory.createThasService(window.thasConfiguration)
}
document.addEventListener('DOMContentLoaded', function () {
  if (window.googleAnalyticsConfiguration !== undefined) {
    GoogleAnalyticsServiceFactory.createGoogleAnalyticsService(window.googleAnalyticsConfiguration)
  }
})
