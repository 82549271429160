<template>
  <nav aria-label="breadcrumb">
    <ul class="breadcrumb mb-0">
      <li v-for="item in items" class="breadcrumb__item">
        <component :is="item.url ? 'a' : 'span'" class="labeled-title labeled-title--small is-inline-flex m-0" :href="item.url">
          <span v-if="item.label" class="labeled-title__label" :class="labelClass">{{ item.label }}</span>
          <span class="labeled-title__text labeled-title__text--bold labeled-title__text--sans-serif">{{ item.text }}</span>
        </component>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    chapterPosition: Number,
    items: Array
  },
  computed: {
    labelClass() {
      const classes = {}
      const chapterBg = `has-chapter-${this.chapterPosition}-bg`
      classes[chapterBg] = true
      return classes
    }
  }
}
</script>
