<template>
  <div :id="identifier">
    <ul class="tabs" :class="[tabsAdditionalClass, { 'tabs--bordered' : bordered }]">
      <li v-for="tab in tabs" class="tabs__item">
        <a @click="selectTab(tab)"
           class="tabs__link"
           :class="applyActiveClass(tab)"
        >
          <span :class="{'tabs__truncated-content': truncate}">{{ tab.name }}</span>
          <slot name="tab-item-inner" :subdomain="tab"></slot>
        </a>
      </li>
    </ul>
    <div class="tab-pages" :class="[pageWrapperClass, { 'tab-pages--animated': animated }]">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tabs: []
    }
  },

  props: {
    bordered: {
      type: Boolean,
      default: false
    },
    animated: {
      type: Boolean,
      default: true
    },
    identifier: String,
    pageWrapperClass: String,
    tabsAdditionalClass: String,
    truncate: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.$children.forEach((child) => {
      if (child.$options.componentName === 'Tab') {
        this.tabs.push(child)
      }
    })
  },

  methods: {
    selectTab(selectedTab) {
      for (let tab of this.tabs) {
        tab.isSelected = (selectedTab.uid === tab.uid)
      }
    },

    applyActiveClass(tab) {
      return tab.isSelected ? 'tabs__link--active' : ''
    }
  }
}
</script>
