import Routing from '../util/routing'

export default class CrossLink {
  constructor (link, options) {
    if (!link.dataset.reference) {
      throw Error('Crosslink does not have required data field "reference"')
    }

    link.addEventListener('click', function (evt) {
      evt.preventDefault()

      if (window.msp === undefined || window.msp.streamCode === '') {
        throw Error('No stream data defined in global namespace!')
      }

      const streamCode = 'streamcode' in link.dataset ? link.dataset.streamcode : window.msp.streamCode
      const target = 'target' in link.dataset ? link.dataset.target : (window.msp.streamCode === streamCode ? '_self' : '_blank')

      const url = Routing.generate('crosslink_target_stream_code', {
        streamCode: streamCode,
        contentReference: link.dataset.reference,
        crosslinkScope: options.crosslinkScope
      })

      options.onClick(url, link, target)
    })
  }
}
