'use strict'

import ImageScaling from './ImageScaling'
import imagesLoaded from 'imagesloaded'
import { EventBus, RESIZE_EVENT, SELECT_TAB_EVENT } from '../common/EventBus'

const MINIMUM_IMAGE_HEIGHT = 300

export class ContainerHeightStrategy {
  constructor (heightModifier) {
    this._heightModifier = heightModifier
  }

  scaleImages (containerElement, images, setImageHeight) {
    // determine max height of visible area
    const maxHeight = Math.max(MINIMUM_IMAGE_HEIGHT, containerElement.clientHeight - this._heightModifier)
    for (const image of images) {
      setImageHeight(image, 0, (imageHeight) => {
        return maxHeight
      })
    }
  }
}

export class HalfWindowHeightStrategy {
  scaleImages (containerElement, images, setImageHeight) {
    const maxHeight = window.innerHeight / 2
    for (const image of images) {
      setImageHeight(image, 0, (imageHeight) => {
        return maxHeight
      })
    }
  }
}

export class MaxVhFullContainerWidthStrategy {

  scaleImages(containerElement, images, setImageHeight) {
    const maxHeight = (window.innerHeight / 100) * 40
    for (const image of images) {
      setImageHeight(image, 0, (imageHeight) => {
        return this.setMaxHeight(maxHeight,image, containerElement)
      })
    }
  }

  setMaxHeight(maxHeight, image, theoryPaneDiv) {
    if(maxHeight > image.clientHeight) {
      maxHeight = image.clientHeight;

      var imageAspectRatio = image.clientWidth / image.clientHeight;
      var paneAspectRatio = theoryPaneDiv.clientWidth / image.clientHeight;

      if (paneAspectRatio < imageAspectRatio) {
        //calculate height by using aspect ratio;
        maxHeight = image.clientHeight * (theoryPaneDiv.clientWidth / image.clientWidth);
      }
    }

    return maxHeight;
  }
}

export class RemoveScrollbarStrategy {
  constructor () {
    this._fallbackStrategy = new ContainerHeightStrategy(0)
  }

  scaleImages (containerElement, images, setImageHeight) {
    if (images.length > 1) {
      this._fallbackStrategy.scaleImages(containerElement, images, setImageHeight)
      return
    }

    for (const image of images) {
      setImageHeight(image, MINIMUM_IMAGE_HEIGHT, (imageHeight) => {
        const scrollHeight = containerElement.scrollHeight
        const clientHeight = containerElement.clientHeight
        const diff = scrollHeight - clientHeight

        if (scrollHeight <= clientHeight) {
          return imageHeight
        }
        return imageHeight - diff
      })
    }
  }
}

class ImageScalingService {
  constructor () {
    this._containerSelectors = {}
    this._questionImageScaling = new ImageScaling()

    EventBus.$on([RESIZE_EVENT, SELECT_TAB_EVENT], () => this.scaleImages())
  }

  add (containerSelector, strategy) {
    if (this._containerSelectors[containerSelector] === undefined) {
      this._containerSelectors[containerSelector] = strategy
    }

    imagesLoaded(containerSelector, () => {
      this._scaleImagesForSelector(containerSelector, strategy)
    })
  }

  remove (containerSelector) {
    if (this._containerSelectors[containerSelector] !== undefined) {
      delete this._containerSelectors[containerSelector]
    }
  }

  scaleImages () {
    for (const selector in this._containerSelectors) {
      this._scaleImagesForSelector(selector, this._containerSelectors[selector])
    }
  }

  _scaleImagesForSelector (selector, strategy) {
    this._questionImageScaling.scaleImages(document.querySelector(selector), strategy)
  }
}

export const imageScalingService = new ImageScalingService()
