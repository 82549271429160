'use strict'

import { EventBus, RESIZE_EVENT } from './EventBus'

export default class WindowResizeEventEmitter {
  constructor () {
    this._resizeTimer = null
    window.addEventListener('resize', this._scheduleResizeEvent.bind(this))
  }

  _scheduleResizeEvent () {
    if (this._resizeTimer !== null) {
      clearTimeout(this._resizeTimer)
    }
    this._resizeTimer = setTimeout(() => {
      EventBus.$emit(RESIZE_EVENT)
      this._resizeTimer = null
    }, 100)
  }
}
