import * as iziToast from 'izitoast'

export default class NotificationToaster {
	constructor (element, options) {
		let {
			toastType,
			toastMessage: message,
			toastDismissable
		} = element.dataset

		let close

		switch (toastType) {
			case 'error':
			case 'warning':
				close = false
				break

			case 'info':
			case 'success':
				close = true
		}

		if (toastDismissable !== undefined) {
			close = toastDismissable === 'true'
		}

		iziToast[toastType]({
			message,
			close,
      icon: '',
			timeout: false,
			position: 'bottomCenter',
			class: 'iziToast--fullwidth',
			animateInside: false,
      color: options.redesign ? 'redesign' : 'red',
		})
	}
}
