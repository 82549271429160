<template>
  <a class="button button--light button--small" :class="[{'button--disabled': !hasProgress}]" @click="showModal">
    <i class="icon-horizontal-bar-chart"></i>
    {{ $t('component.progress.button.title') }}
  </a>
</template>

<script>
  import { EventBus, PROGRESS_MODAL_OPEN_EVENT } from '../../common/EventBus'

  export default {
    props: {
      hasProgress: Boolean,
      progressUrl: String
    },
    methods: {
      showModal() {
        EventBus.$emit(PROGRESS_MODAL_OPEN_EVENT, this.progressUrl)
      }
    }
  }
</script>
