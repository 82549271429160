<template>
  <div
    class="w-screen h-16 transform transition-transform ease-out duration-500 bg-red-dark bg-opacity-70 flex justify-center gap-x-2 items-center text-white font-bold text-lg fixed bottom-0 px-5"
    :class="transformClass"
  >
    <div>
      <span v-html="$t('component.preferred_stream.notification_redesign')"></span>
      <a class="underline text-white hover:text-red-extra-light" :href="url" v-html="$t('component.preferred_stream.notification_link_redesign')"></a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      visible: false
    }
  },
  computed: {
    transformClass() {
      return this.visible ? 'translate-y-0' : 'translate-y-full'
    }
  },
  props: {
    url: String
  },
  mounted() {
    setTimeout(() => {
      this.visible = true
    }, 3000)
  }
}
</script>
