import {
  EventBus,
  TOOL_USE_EVENT,
  ENABLE_REVIEW_EVENT,
  SUBMITTED_QUESTION_ENABLED_EVENT
} from '../common/EventBus'

const GA_TOGGLE_REVIEW_EVENT = 'toggle_enable_review'
const GA_SUBMITTED_QUESTION_ENABLED = 'submitted_question_enabled'

class GoogleAnalyticsService {
  constructor (streamCode) {
    EventBus.$on(SUBMITTED_QUESTION_ENABLED_EVENT, (userReference, contentPath) => {
      gtag('event', GA_SUBMITTED_QUESTION_ENABLED, {
        user_reference: userReference,
        content_path: contentPath,
        stream_code: streamCode
      });
    })
    EventBus.$on(ENABLE_REVIEW_EVENT, (reviewType) => {
      gtag('event', GA_TOGGLE_REVIEW_EVENT, {
        review_type: reviewType,
        stream_code: streamCode
      });
    })
    EventBus.$on(TOOL_USE_EVENT, (href, toolType, action = 'clicked') => {
      if (toolType) {
        gtag('event', action, {
          event_category: toolType
        });
      }
    })
  }
}

export class GoogleAnalyticsServiceFactory {
  static createGoogleAnalyticsService (configuration) {
    return new GoogleAnalyticsService(configuration.arrangementCode, configuration.isTeacher)
  }
}
