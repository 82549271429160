const NAVBAR_HEIGHT = 50;

export default class TheoryPageHeader {
  constructor(element) {
    this.element = element;

    this.bounding = element.getBoundingClientRect();
    this.barHeight = this.bounding.height;

    this.topDist = element.offsetTop - NAVBAR_HEIGHT;

    this.sticky = false;

    this.contentContainer = document.querySelector('.js-theory-content');
    if (null === this.contentElement) {
      throw Error('Required content container not present on page');
    }

    this.setPosition();
    document.addEventListener('scroll', this.onScroll.bind(this));
  }

  setPosition() {
    const offset = window.pageYOffset;
    const distance = this.topDist - offset;
    if (distance < 0 && !this.sticky) {
      this.element.classList.add('theory-page-header--fixed');
      this.contentContainer.style.paddingTop = `${this.barHeight}px`;
      this.sticky = true;
    } else if (this.sticky && (offset <= this.topDist)) {
      this.element.classList.remove('theory-page-header--fixed');
      this.contentContainer.style.paddingTop = null;
      this.sticky = false;
    }
  }

  onScroll() {
    this.setPosition();
  }
}
