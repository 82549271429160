var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    {
      staticClass: "button button--light button--small",
      class: [{ "button--disabled": !_vm.hasProgress }],
      on: { click: _vm.showModal },
    },
    [
      _c("i", { staticClass: "icon-horizontal-bar-chart" }),
      _vm._v("\n  " + _vm._s(_vm.$t("component.progress.button.title")) + "\n"),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }